import React from "react";
import { Button, Header } from "ui/src";
import { Chat } from "@noa/types";
import { SidebarSimple } from "@phosphor-icons/react";
import { useParams } from "react-router-dom";
import { useLayout } from "~/context/LayoutContext";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { getChatTitle } from "~/utils/chat";
import { ChatContextMenu } from "~/components/Chat/ChatHeader/ChatContextMenu";
import { AccountMenu } from "~/components/AccountMenu/AccountMenu";
import { useApplication } from "~/context/ApplicationContext";
import ExportChatButton from "../ExportChatButton/ExportChatButton";

interface NoaHeaderProps {
  showContextMenu?: boolean;
  taskTitle?: string;
  chat?: Chat;
  onRename?: () => void;
  onDelete?: () => void;
}

const MAX_LENGTH = 50;

const NoaHeader = ({
  showContextMenu = true,
  chat,
  onDelete,
  onRename,
  taskTitle,
}: NoaHeaderProps) => {
  const { id: chatId } = useParams();
  const { features } = useApplication();

  const isDownloadButtonVisible = chatId && features.chat.download.pdf;

  const { showSidebar, toggleSidebar } = useLayout();
  const { log } = useAnalytics();
  const title = taskTitle || getChatTitle(chat) || "New chat";
  const truncatedText =
    title.length > MAX_LENGTH ? `${title.slice(0, MAX_LENGTH)}...` : title;

  const onToggleSidebar = () => {
    log({
      type: "sidebar_toggle_clicked",
      payload: {
        closed: !showSidebar,
      },
    });

    toggleSidebar();
  };

  return (
    <Header className="flex items-center">
      {!showSidebar && (
        <Button
          size="icon"
          variant="ghost"
          onClick={onToggleSidebar}
          data-testid="chat-header-sidebar-toggle"
          className="mr-2"
        >
          <SidebarSimple className="h-8 w-8 text-icons-50" />
        </Button>
      )}

      <div className="flex-1 flex items-center overflow-hidden">
        <div className="flex items-center w-0 flex-1 overflow-hidden">
          <span className="text-xl font-medium truncate">{truncatedText}</span>

          {showContextMenu && chat && onRename && onDelete && (
            <ChatContextMenu onRename={onRename} onDelete={onDelete} />
          )}
        </div>
        {isDownloadButtonVisible && <ExportChatButton chatId={chatId} />}
      </div>

      <AccountMenu />
    </Header>
  );
};

export default NoaHeader;
