import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { File } from "@phosphor-icons/react";

import { UploadScreen } from "./UploadScreen";
import { UploadingScreen } from "./UploadingScreen";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { FileUploadError } from "~/components/FileUpload/FileUploadError";

interface Props {
  onClose(): void;

  onSuccess(fileId: string): Promise<void>;

  onNewChat(): void;
}

export type FileUploadErrorCode =
  | "FILE_TYPE_UNSUPPORTED"
  | "FILE_SIZE_EXCEEDED"
  | "FILE_UPLOAD_FAILED"
  | "FILE_COUNT_EXCEEDED"
  | "FILE_PROCESSING_ERROR"
  | "FILE_CORRUPTED";

export function FileUploadModal({ onClose, onSuccess, onNewChat }: Props) {
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<FileUploadErrorCode>();
  const { log } = useAnalytics();

  const onCloseModal = () => {
    log({ type: "file_upload_modal_closed" });

    onClose();
  };

  const onStartNewChat = () => {
    log({ type: "file_upload_new_chat_clicked" });

    onNewChat();
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => setFiles(acceptedFiles),
    [],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFileDialogActive,
    fileRejections,
  } = useDropzone({
    multiple: false,
    maxFiles: 1,
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
  });

  useEffect(() => {
    if (!fileRejections.length) {
      return;
    }

    const codes = fileRejections.flatMap(({ errors }) =>
      errors.map(({ code }) => code),
    );

    if (codes.includes("file-invalid-type")) {
      setError("FILE_TYPE_UNSUPPORTED");
      return;
    }

    if (codes.includes("too-many-files")) {
      setError("FILE_COUNT_EXCEEDED");
    }
  }, [fileRejections]);

  const onClear = () => {
    log({ type: "file_upload_try_again_clicked" });

    setFiles([]);
    fileRejections.slice(0, fileRejections.length);
    setError(undefined);
  };

  const isActive = isDragActive || isFileDialogActive;

  const isUploading = files.length > 0 && !fileRejections.length;

  return (
    <>
      <FileUploadError
        error={error}
        onClose={onCloseModal}
        onClear={onClear}
        onNewChat={onStartNewChat}
      />

      {isUploading && (
        <UploadingScreen
          files={files}
          setError={setError}
          onSuccess={onSuccess}
        />
      )}

      {!isUploading && (
        <UploadScreen
          isActive={isActive}
          rootProps={getRootProps()}
          inputProps={getInputProps()}
        />
      )}
    </>
  );
}
