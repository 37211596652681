import "./integrations/sentry";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { TextProvider } from "@noa/locales";
import { TooltipProvider } from "ui";
import * as Sentry from "@sentry/react";
import { strings as en } from "~/locales/en";
import { AuthContextProvider } from "./context/AuthContext";
import { HeaderProvider } from "~/context/HeaderContext";
import { ApplicationProvider } from "~/context/ApplicationContext";
import { LayoutContextProvider } from "./context/LayoutContext";
import { AnalyticsProvider } from "~/context/AnalyticsProvider";
import { NotificationsProvider } from "~/context/NotificationsContext";
import { initialiseLaunchDarkly } from "~/integrations/launch-darkly";

import { ThemeProvider } from "~/context/ThemeProvider";
import { FeatureFlagProvider } from "~/context/FeatureFlagProvider";
import App from "./App";

import "ui/styles/global.css";
import "./index.css";
import { ErrorBoundaryPage } from "./components/ErrorBoundary/ErrorBoundaryPage";

const root = createRoot(document.getElementById("root")!);
const errorBoundaryPage = <ErrorBoundaryPage />;

async function load() {
  const LaunchDarklyProvider = await initialiseLaunchDarkly();

  root.render(
    <StrictMode>
      <ThemeProvider>
        <BrowserRouter>
          <TextProvider value={en}>
            <Sentry.ErrorBoundary fallback={errorBoundaryPage}>
              <LaunchDarklyProvider>
                <FeatureFlagProvider>
                  <ApplicationProvider>
                    <NotificationsProvider>
                      <AnalyticsProvider>
                        <AuthContextProvider>
                          <LayoutContextProvider>
                            <HeaderProvider>
                              <TooltipProvider>
                                <App />
                              </TooltipProvider>
                            </HeaderProvider>
                          </LayoutContextProvider>
                        </AuthContextProvider>
                      </AnalyticsProvider>
                    </NotificationsProvider>
                  </ApplicationProvider>
                </FeatureFlagProvider>
              </LaunchDarklyProvider>
            </Sentry.ErrorBoundary>
          </TextProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StrictMode>,
  );
}

load().then();
