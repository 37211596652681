import React from "react";
import { ErrorBoundaryHeader } from "./ErrorBoundaryHeader";
import { ErrorBoundaryHero } from "./ErrorBoundaryHero";

export function ErrorBoundaryPage() {
  return (
    <div className="flex flex-col w-full" data-testid="error-boundary-page">
      <ErrorBoundaryHeader />
      <ErrorBoundaryHero className="flex-1" />
    </div>
  );
}
