import React from "react";
import { Link } from "react-router-dom";
import { Button } from "ui";
import { Text } from "~/components/Text";
import { NoaErrorIcon } from "~/assets/icons/NoaErrorIcon";
import { cn } from "~/lib/utils";

interface ErrorBoundaryHeroProps {
  className?: string;
}

export function ErrorBoundaryHero({ className }: ErrorBoundaryHeroProps) {
  return (
    <main
      className={cn("flex flex-col items-center gap-10 mt-[100px]", className)}
    >
      <NoaErrorIcon height="200" width="250" />
      <p className="text-2xl font-semibold">
        <Text id="error-boundary.title" />
      </p>

      <p className="text-center">
        <Text id="error-boundary.message" />
        <br />
        <Text id="error-boundary.contact-support" />
      </p>

      <Link to="/support">
        <Button>
          <Text id="error-boundary.contact-support-btn" />
        </Button>
      </Link>
    </main>
  );
}
