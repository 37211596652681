import { LaunchDarklyFeatureFlags } from "~/integrations/launch-darkly/LaunchDarklyContext";

export function parseFeatureFlags(flags: Partial<LaunchDarklyFeatureFlags>) {
  return {
    chat: {
      download: {
        pdf: flags.rolloutChatPdfDownload ?? false,
      },
      image: {
        upload: flags.rolloutChatImageUpload ?? false,
      },
    },
    tasks: {
      personas: flags.rolloutTasksPersonas ?? false,
    },
    maintenance: flags.operationalMaintenance ?? false,
    theme: {
      notification: flags.rolloutThemeNotification ?? false,
    },
    notifications: {
      chromeExtension: flags.rolloutChromeExtensionNotification ?? false,
    },
  };
}
