import { Header, NoaLogo } from "ui";
import React from "react";
import { Link } from "react-router-dom";
import { cn } from "~/lib/utils";

interface ErrorBoundaryHeaderProps {
  className?: string;
}

export function ErrorBoundaryHeader({ className }: ErrorBoundaryHeaderProps) {
  return (
    <Header className={cn("flex items-center", className)}>
      <Link to="/">
        <NoaLogo className="h-6" />
      </Link>
    </Header>
  );
}
